
import { PROVIDER, SERVICES as S } from "@/config/literals";
import { Component, Vue, Inject, Prop } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";
import _ from "lodash";

import CreateUi from "@/components/Core/Create/CreateUI.vue";

import { IGenerationService } from "@/services";

@Component({
  components: {
    CreateUi,
  },
})
export default class GenerationCreate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  forbiddenNames!: string[];

  get generationService() {
    return this.container.resolve<IGenerationService>(S.GENERATION_SERVICE);
  }
  
  loading: boolean = false;
  name: string | null = null;

  async create() {
    try {
      this.loading = true;
      const generationCreated = await this.generationService.create({
        name: this.name!,
      });
      this.$emit("create", generationCreated);
    } finally {
      this.loading = false;
    }
  }

  isAllowed(name: string): boolean {
    return (
      _.findIndex(
        this.forbiddenNames,
        (s) => s.toUpperCase() == name.toUpperCase()
      ) != -1
    );
  }
}
